<template>
    <div class="faq">
        <Header />
        <div class="banner">
            <div class="container">
                <div class="title">HOW TO IMPROVE THE MARKET VALUE OF YOUR CAR</div>
            </div>
        </div>
        <div class="main">

            <div class="container">

                <div class="warpper">

                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h1>HOW TO IMPROVE THE MARKET VALUE OF YOUR CAR</h1>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <p>Looking after your belongings and maintaining the products you buy is an art closely valued by many. Turning a blind eye towards your household stuff is considered casual and depreciated by people around. A person, no matter filthy rich or belonging to a middle class, if he values his accessories and takes good care of his house, family, property and car, reflects a great character and responsibility.</p>
                            <p>A gearhead will genuinely understand the importance of maintaining a vehicle. Here are the top 5 tips by the experts of car evaluation for improving the market value of your car.</p>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>PAMPER YOUR CAR LIKE A CHILD</h3>
                            <p>Take a hands-on approach regarding your vehicle maintenance. Make a routine and clean it regularly whatsoever the circumstances. Please protect it from direct sunlight during summers as it damages the screen and interior of your car. Avoid taking it out in the rain as it ruins tyres paint and causes rusting of the body. Park it under a shade or a garage. Clean it properly, especially after coming back from a long drive; here route is bumpy, and you suspect a heavy load of wear and tear. Please give it a regular service at least once a month so that any underlying dirt can be washed away. Rubbing a compound or car polish will add to the shine of the body and appearance.</p>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>ROUTINE VISITS TO GARAGE FOR GENERAL INSPECTION</h3>
                            <p>Make it a habit to visit your trusted car mechanic for a general check-up of your car like oil change, engine sound and work up, deep cleansing of the engine, battery status, water levels in the radiator, brake oil and maintenance of tyres. Oil change after 2000-3000 km travel is a must, and showing negligence over it can downgrade the value of your car and engine health. Regularly monitor the air pressure in your tyres after 10-12 days. These things might be of little concern for many, but if you are expecting your car to be sold out at the desired rate with a reasonable margin of profit, this strategy adds to the chances of earning a significant churn of money.</p>
                        </div>
                    </div>

                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>GET YOUR DAMAGED CAR REPAIRED</h3>
                            <p>If somehow you end up having an accident, consider it your obligation to get your car repaired at your earliest convenience. Minor scratches, dents, broken mirrors or screens give an unwelcoming impression, and potential buyers might look away from getting into a business deal with you. Technically, you are saving a great deal of time and car's depreciation by prioritising its repair.</p>

                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>ORGANISE THE CAR DOCUMENTATION</h3>
                            <p>Get your car registration and documents in order. Previous service reports, evidence of garage visits, any taxation papers or PPSR reports that could encourage the buyer to pay the Tag value of your car is important.</p>

                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>MODIFY YOUR VEHICLE WITH TIME</h3>
                            <p>A well-experienced buyer would want to point out the minor details and ask for the parts that come in with the car. So always remember to keep a tool kit, extra tyre, manual logbook, protective sheets of the car with you. Give the dealer or a private buyer no reason to question your car's worth. Modify your car with the need of time. Install an excellent sound system, change the mats of your vehicle, switch your car fragrance and get rid of anything that gives a smelly vibe.</p>
                            <p>If you need any professional help <router-link  to="/">reach out us</router-link> one of our professional will be happy to assist.</p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <Footer />
    </div>
</template>
  
<script>
import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {};
    },
};
</script>
  
<style scoped lang="less">
.ddRow {
    font-family: Ubuntu-Regular, Ubuntu;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    margin: 1%;
    margin-bottom: 36px;
    width: 100%;
}

.ddRow h2,
.ddRow h1,
.ddRow h3 {
    font-weight: bold;
    color: #004AAD;
    margin: 4% 0 2% 0;
    text-align: center;
}

.ddRow ul li {
    list-style-type: disclosure-closed;
    margin-bottom: 2%;
    margin-left: 4%;
}

.ddRow img {
    width: 100%;
}

.ddCol-12 {
    width: 100%;
}

.ddCol-6 {
    width: 50%;
    padding: 2%;
    float: left;
}

.ddRow:after {
    content: "";
    display: table;
    clear: both;
}

@media only screen and (max-device-width: 960px) {
    .ddRow {
        display: block;
    }

    .ddCol-6 {
        width: 100%;
    }
}

.faq {
    width: 100%;
    position: relative;

    .banner {
        width: 100%;
        height: 286px;
        background: url("~@/assets/images/banner_3.png") no-repeat;
        background-size: cover;
        background-position: center;
        padding: 160px 0 80px 0;
        box-sizing: border-box;

        .title {
            font-size: 40px;
            font-family: Ubuntu-Bold, Ubuntu;
            font-weight: bold;
            color: #ffffff;
            line-height: 47px;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 690px) {
    .faq {
        .banner {
            height: 180px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}

@media only screen and (max-width: 1050px) and (min-width: 690px) {
    .faq {
        .banner {
            height: 180px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}</style>
  